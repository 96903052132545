import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home";
import Login from "@/views/Login";
import LoginHelp from "@/views/LoginHelp";
import Signup from "@/views/Signup";
import PageNotFound from "@/views/PageNotFound";
import ProfilesGate from "@/views/profiles/ProfilesGate/ProfilesGate";
import ManageProfiles from "@/views/profiles/ManageProfiles/ManageProfiles";
import Logout from "@/views/Logout";
import YourAccount from "@/views/YourAccount/YourAccount";
import LanguageSetup from "@/views/profiles/LanguageSetup/LanguageSetup";
import Movies from "@/views/NewMovies/NewMovies";
import AllMovies from "@/views/Movies/Movies";
import TVGuide from "@/views/TVGuide/TVGuide";
import MyList from "@/views/MyList";
import SportsVOD from "@/views/SportsVOD";
import ComingSoon from "@/views/ComingSoon";
import Search from "@/views/Search/Search";
import TVShows from "@/views/TVShows";
import PlayerWrap from "@/views/Player/PlayerWrap";
import LiveTv from "@/views/LiveTv/LiveTv";
import { getItem, setItem } from "@/helpers/storage";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      public: true,
    },
  },
  {
    path: "/sports",
    name: "SportsVOD",
    meta: {
      requiresAuth: true,
    },
    component: SportsVOD,
    props: { pageName: "sportsVOD" },
  },
  {
    path: "/installation",
    name: "Install Application",
    meta: {
      requiresAuth: true,
    },
    component: ComingSoon,
    props: { pageName: "installation" },
  },
  {
    path: "/livetv",
    name: "LiveTv",
    component: LiveTv,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tvshows",
    name: "TVShows",
    meta: {
      requiresAuth: true,
    },
    component: TVShows,
    props: { pageName: "TvShows" },
  },
  {
    path: "/tvguide",
    name: "TVGuide",
    component: TVGuide,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/video/:slug",
    name: "Video",
    component: PlayerWrap,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mylist",
    name: "MyList",
    component: MyList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "Sign In",
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: "/loginguid/:username/:guid",
    name: "loginguid",
    component: Login,
    meta: {
      requiresAuth: false,
      public: true,
    },
  },
  {
    path: "/loginTrial/:lead/:guid",
    name: "loginTrial",
    component: Login,
    meta: {
      requiresAuth: false,
      public: true,
    },
  },
  {
    path: "/loginHelp",
    name: "Login Help",
    component: LoginHelp,
    meta: {
      public: true,
    },
  },
  {
    path: "/Signup",
    name: "Sign Up",
    component: Signup,
    meta: {
      public: true,
    },
  },
  {
    path: "/profiles",
    name: "profiles",
    children: [
      {
        path: "manage",
        name: "ManageProfiles",
        component: ManageProfiles,
      },
      {
        path: "languagesetup",
        name: "LanguageSetup",
        component: LanguageSetup,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ProfilesGate",
    name: "ProfilesGate",
    component: ProfilesGate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/allMovies",
    name: "All Movies",
    component: AllMovies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/movies",
    name: "Movies",
    component: Movies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/YourAccount",
    name: "YourAccount",
    component: YourAccount,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      public: true,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound fallback',
    component: PageNotFound,
    meta: { public: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let isFirstNavigation = true;
router.beforeEach((to, from, next) => {
  if (isFirstNavigation) {
    if (!getItem("accessToken")) setItem('savedPath', to.fullPath);
    isFirstNavigation = false;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (getItem("accessToken")) {
      next();
      return;
    }
    next("/");
    return;
  }
  if (to.matched.some((record) => record.meta.public)) {
    if (!getItem("accessToken")) {
      next();
      return;
    }
    next("/movies");
    return;
  }
});
export default router;
